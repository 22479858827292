import { useRouter } from 'next/router';
import { BoxProps } from '@chakra-ui/react';

import { useUnreadCount } from '@arena-labs/chat';
import {
  DataInsightsIcon,
  HeadsetIcon,
  HomeIcon,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import { IfWearable } from '@strive/wearable';

import { useStriveChatContext } from '../chat/chat-provider';
import { FooterLink } from '../layout/footer-link';
import { FooterNav } from '../layout/footer-nav';
import { FooterNotification } from '../layout/footer-notification';
import { useFeatureUpdateIndicator } from '../version-update/feature-update-indicator';

export type LaunchpadFooterProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function LaunchpadFooter({
  sticky,
  tabs,
  ...props
}: LaunchpadFooterProps) {
  const route = useRouter();
  const { data: user } = useUserProfile();
  const { channel } = useStriveChatContext();
  const unreadCount = useUnreadCount(channel);

  const enableChat = Boolean(user?.profile.channel_name_with_launchpad);

  const newIndicator = {
    Launchpad: useFeatureUpdateIndicator('Launchpad'),
    OnboardGuide: useFeatureUpdateIndicator('OnboardGuide'),
    Data: useFeatureUpdateIndicator('Data'),
  };

  return (
    <FooterNav tabs={tabs} sticky={sticky} {...props}>
      <FooterLink
        href="/"
        label="Home"
        icon={HomeIcon}
        isCurrent={route.pathname === '/'}
      >
        <FooterNotification id="Home" isNew={newIndicator.Launchpad} />
      </FooterLink>
      {enableChat && (
        <FooterLink
          href="/chat"
          label="Onboard Guide"
          icon={HeadsetIcon}
          isCurrent={route.asPath.startsWith('/chat')}
        >
          <FooterNotification
            id="chat"
            count={unreadCount}
            isNew={newIndicator.OnboardGuide}
          />
        </FooterLink>
      )}
      <IfWearable>
        <FooterLink
          href="/progress/"
          label="Progress"
          icon={DataInsightsIcon}
          isCurrent={route.asPath.startsWith('/progress')}
        />
      </IfWearable>
    </FooterNav>
  );
}
